<template>
  <div class="line-item-v2">
    <div class="custom-grey-border">
      <div class="d-flex grey lighten-4">
        <div
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
        >
          #
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 text-left"
          style="width: 100%"
        >
          Line Item
        </div>
        <div
          v-if="show_price"
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.unit}px; max-width: ${vWidth.unit}px; width: ${vWidth.unit}px`"
        >
          Unit
        </div>
        <div
          v-if="show_price"
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.quantity}px; max-width: ${vWidth.quantity}px; width: ${vWidth.quantity}px`"
        >
          Quantity
        </div>
        <template v-if="!isJob">
          <div
            class="color-custom-blue py-2 font-weight-700 text-center"
            :style="`min-width: ${vWidth.rate}px; max-width: ${vWidth.rate}px; width: ${vWidth.rate}px`"
          >
            Unit Cost
          </div>
          <div
            v-if="show_price && discount_level == 'line_item'"
            class="color-custom-blue py-2 font-weight-700 text-center"
            :style="`min-width: ${vWidth.discount}px; max-width: ${vWidth.discount}px; width: ${vWidth.discount}px`"
          >
            Discount
          </div>
          <div
            v-if="show_price"
            class="color-custom-blue py-2 font-weight-700 text-center"
            :style="`min-width: ${vWidth.total}px; max-width: ${vWidth.total}px; width: ${vWidth.total}px`"
          >
            Total
            <template v-if="quoteType == 2">
              <v-tooltip left content-class="custom-left-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1"
                    small
                    color="color-custom-blue"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span
                  >(No. of Contract
                  Days)&nbsp;&nbsp;X&nbsp;&nbsp;(Quantity)&nbsp;&nbsp;X&nbsp;&nbsp;(Unit
                  Cost)</span
                >
              </v-tooltip>
            </template>
          </div>
        </template>
      </div>
      <ul v-if="lViewLineItem && lViewLineItem.length">
        <li
          v-for="(row, index) in lViewLineItem"
          :class="row.alternate_class"
          :key="`line-item-drag-${index}`"
        >
          <div
            :key="`line-item-drag-children-${index}`"
            :id="`line-item-drag-${index}`"
            class="d-flex py-2 custom-border-top"
            style="position: relative"
            :class="{
              'item-is-child': row.is_child,
              'item-is-second-child': row.is_second_child,
            }"
          >
            <div
              class="font-weight-600 text-center"
              :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
            >
              <span v-if="row.sno && !row.is_child && !row.is_second_child">
                {{ row.sno }}.
              </span>
            </div>

            <template v-if="row.is_child || row.is_second_child">
              <div
                class="font-weight-600 text-center"
                :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
              >
                <span v-if="!row.is_second_child"> {{ row.sno }}. </span>
              </div>
              <div
                v-if="row.is_second_child"
                class="font-weight-600 text-center"
                :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
              >
                {{ row.sno }}.
              </div>
            </template>

            <div class="text-left" style="width: 100%">
              <div class="d-flex">
                <v-avatar v-if="show_image" size="80">
                  <img
                    v-if="row?.image?.url"
                    :src="row?.image?.url"
                    :alt="row?.image?.name"
                  />
                  <img v-else :src="$defaultImage" />
                </v-avatar>
                <div style="width: 100%" :class="{ 'ml-4': show_image }">
                  <v-text-field
                    readonly
                    v-model="row.product"
                    class="line-item-header-text px-0"
                    :placeholder="lodash.capitalize(row.product_type)"
                    dense
                    filled
                    solo
                    flat
                    color="cyan"
                  >
                    <template v-slot:append>
                      <v-chip
                        color="cyan white--text"
                        label
                        class="lip-type text-uppercase font-weight-600"
                        :class="{
                          product: row.product_type == 'door',
                          service: row.product_type == 'service',
                          equipment: row.product_type == 'parts',
                          other: row.product_type == 'other',
                          tool: row.product_type == 'tools',
                          door_type: row.product_type == 'door_type',
                          'btx-header': row.product_type == 'header',
                        }"
                      >
                        {{ lodash.lowerCase(row.type) }}
                      </v-chip>
                      <template v-if="row.product_type == 'door_type'">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              color="cyan white--text"
                              label
                              v-on:click="view_qty_dialog(row)"
                              class="door-type-action text-uppercase font-weight-600"
                            >
                              <v-icon small>mdi-cog</v-icon>
                            </v-chip>
                          </template>
                          View Door Type.
                        </v-tooltip>
                      </template>
                    </template>
                  </v-text-field>
                  <v-textarea
                    class="px-0"
                    v-model="row.description"
                    placeholder="Description"
                    dense
                    filled
                    readonly
                    solo
                    flat
                    rows="2"
                    auto-grow
                    color="cyan"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="show_price"
              class="text-center"
              :style="`min-width: ${vWidth.unit}px; max-width: ${vWidth.unit}px; width: ${vWidth.unit}px`"
              style="position: relative"
            >
              <v-text-field
                class="line-item-header-text px-0 text-capitalize"
                placeholder="Unit"
                dense
                filled
                readonly
                solo
                flat
                :value="lodash.capitalize(row.unit)"
                color="cyan"
              />
              <div style="position: absolute; left: 10px">
                <template v-if="row.has_maintenance">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        color="orange darken-3 white--text"
                        class="font-weight-700 text-capitalize"
                        small
                        >M</v-chip
                      >
                    </template>
                    <span>Already have Maintenance</span>
                  </v-tooltip>
                </template>
                <template v-if="row.request_type">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        color="cyan darken-3 white--text"
                        class="ml-2 font-weight-700 text-capitalize"
                        small
                      >
                        {{ row?.request_type?.charAt(0) }}
                      </v-chip>
                    </template>
                    <span class="text-capitalize"
                      >Type : {{ row?.request_type }}</span
                    >
                  </v-tooltip>
                </template>
              </div>
            </div>
            <div
              v-if="show_price"
              class="text-center"
              :style="`min-width: ${vWidth.quantity}px; max-width: ${vWidth.quantity}px; width: ${vWidth.quantity}px`"
            >
              <v-text-field
                class="line-item-header-text px-0"
                placeholder="Quantity"
                dense
                readonly
                filled
                solo
                flat
                type="number"
                v-model="row.quantity"
                color="cyan"
              />
            </div>
            <template v-if="!isJob">
              <template v-if="row.custom_val">
                <div
                  class="text-center"
                  :style="`min-width: ${
                    vWidth.rate + vWidth.total
                  }px; max-width: ${vWidth.rate + vWidth.total}px; width: ${
                    vWidth.rate + vWidth.total
                  }px`"
                >
                  <v-text-field
                    class="line-item-header-text px-0"
                    placeholder="Custom Value"
                    dense
                    filled
                    readonly
                    solo
                    flat
                    v-model="row.custom_val"
                    color="cyan"
                  />
                </div>
              </template>
              <template v-else>
                <div
                  class="text-center"
                  :style="`min-width: ${vWidth.rate}px; max-width: ${vWidth.rate}px; width: ${vWidth.rate}px`"
                >
                  <v-text-field
                    class="line-item-header-text px-0"
                    placeholder="Unit Cost"
                    dense
                    filled
                    readonly
                    solo
                    flat
                    type="number"
                    v-model="row.rate"
                    color="cyan"
                  />
                </div>
                <div
                  v-if="show_price && discount_level == 'line_item'"
                  class="text-center"
                  :style="`min-width: ${vWidth.discount}px; max-width: ${vWidth.discount}px; width: ${vWidth.discount}px`"
                >
                  <div class="d-flex">
                    <v-text-field
                      readonly
                      hide-details
                      class="line-item-header-text px-0"
                      placeholder="Discount"
                      dense
                      filled
                      solo
                      flat
                      type="number"
                      v-model="row.discount_value"
                      color="cyan"
                      style="width: 50%; min-width: unset"
                    />
                    <v-select
                      readonly
                      :items="discount_type_list"
                      v-model="row.discount_type"
                      hide-details
                      item-text="type"
                      item-value="value"
                      item-color="cyan"
                      class="line-item-header-text discount-type text-h6 px-0"
                      placeholder="Type"
                      dense
                      filled
                      solo
                      flat
                      color="cyan"
                      style="width: 50%"
                    />
                  </div>
                </div>
                <div
                  v-if="show_price"
                  class="text-center"
                  :style="`min-width: ${vWidth.total}px; max-width: ${vWidth.total}px; width: ${vWidth.total}px`"
                >
                  <v-text-field
                    class="line-item-header-text px-0"
                    placeholder="Total"
                    dense
                    filled
                    readonly
                    solo
                    flat
                    type="number"
                    v-model="row.total"
                    color="cyan"
                  />
                </div>
              </template>
            </template>
          </div>
        </li>
      </ul>
      <template v-else>
        <div class="custom-border-top p-3">
          <p
            class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border d-flex align-center"
          >
            <v-icon class="mr-1">mdi-alert-circle-outline</v-icon>
            Oops... Nothing Found.
          </p>
        </div>
      </template>
    </div>
    <div v-if="!isJob">
      <table width="100%" style="table-layout: fixed">
        <tbody>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Sub Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(lViewCalculation.sub_total) }}
            </td>
          </tr>
          <template v-if="lViewCalculation.discount_level == 'transaction'">
            <tr>
              <td
                width="70%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                Discount
              </td>
              <td width="15%" valign="middle" align="center">
                <div class="d-flex">
                  <v-text-field
                    readonly
                    hide-details
                    class="line-item-header-text px-0"
                    placeholder="Discount"
                    dense
                    filled
                    solo
                    flat
                    v-model="lViewCalculation.discount_value"
                    color="cyan"
                  />
                  <v-select
                    readonly
                    :items="discount_type_list"
                    v-model="lViewCalculation.discount_type"
                    hide-details
                    item-text="type"
                    item-value="value"
                    item-color="cyan"
                    class="line-item-header-text discount-type text-h6 px-0"
                    append-icon=""
                    placeholder="Type"
                    dense
                    filled
                    solo
                    flat
                    color="cyan"
                  />
                </div>
              </td>
              <td
                width="15%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                {{ formatMoney(lViewCalculation.discount_amount) }}
              </td>
            </tr>
            <tr>
              <td
                width="70%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                <v-text-field
                  readonly
                  hide-details
                  style="width: 250px"
                  class="line-item-header-text px-0"
                  placeholder="Discount Label"
                  dense
                  filled
                  solo
                  flat
                  v-model="lViewCalculation.ctx_discount_label"
                  color="cyan"
                />
              </td>
              <td width="15%" valign="middle" align="center">
                <div class="d-flex">
                  <v-text-field
                    readonly
                    hide-details
                    class="line-item-header-text px-0"
                    placeholder="Discount"
                    dense
                    filled
                    solo
                    flat
                    v-model="lViewCalculation.ctx_discount_value"
                    color="cyan"
                  />
                  <v-select
                    append-icon=""
                    readonly
                    :items="discount_type_list"
                    v-model="lViewCalculation.ctx_discount_type"
                    hide-details
                    item-text="type"
                    item-value="value"
                    item-color="cyan"
                    class="line-item-header-text discount-type text-h6 px-0"
                    placeholder="Type"
                    dense
                    filled
                    solo
                    flat
                    color="cyan"
                  />
                </div>
              </td>
              <td
                width="15%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                {{ formatMoney(lViewCalculation.ctx_discount) }}
              </td>
            </tr>
          </template>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Tax [GST {{ lViewCalculation.tax_value }}%]
            </td>
            <td width="15%" valign="middle" align="center">
              <v-checkbox
                readonly
                v-model="lViewCalculation.tax_active"
                class="my-0 py-0 px-0 mx-0"
                color="cyan"
                :label="`Taxable Amount: ${formatMoney(
                  lViewCalculation.tax_amount
                )}`"
              />
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(lViewCalculation.tax_amount) }}
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Adjustment
            </td>
            <td width="15%" valign="middle" align="center">
              <v-text-field
                readonly
                hide-details
                class="line-item-header-text px-0 line-item-adjustment"
                placeholder="Adjustment"
                dense
                filled
                solo
                flat
                v-model="lViewCalculation.adjustment"
                color="cyan"
              >
              </v-text-field>
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(lViewCalculation.adjustment) }}
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Grand Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(lViewCalculation.total) }}
            </td>
          </tr>
          <tr
            v-for="(adrow, kindex) in lViewCalculation.additional_rows"
            :key="`add-row-${kindex}`"
          >
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ adrow.label }}
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(adrow.value) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Dialog :common-dialog="qty_dialog" :dialog-width="dialog_width">
      <template v-slot:title>
        <v-layout>
          <v-flex> Door Type - {{ qty_object.product }} </v-flex>
          <v-flex class="text-right">
            <v-btn
              style="height: 32px !important"
              class="custom-bold-button"
              depressed
              v-on:click="close_qty_dialog()"
              >Close</v-btn
            >
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:body>
        <div class="custom-grey-border">
          <table width="100%" style="table-layout: fixed">
            <thead>
              <tr>
                <th
                  class="font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                  :width="quoteType == 2 ? '40%' : '60%'"
                >
                  Size
                </th>
                <th
                  v-if="isJob || quoteType == 2"
                  class="font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                  width="20%"
                >
                  Serial Number
                </th>
                <th
                  class="font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                  width="20%"
                >
                  QTY
                </th>
                <th
                  v-if="!isJob"
                  class="font-weight-700 color-custom-blue grey lighten-4 text-uppercase pl-4"
                  width="20%"
                >
                  Price
                </th>
              </tr>
            </thead>
            <tbody v-if="qty_array && qty_array.length">
              <tr
                v-for="(row, index) in qty_array"
                :key="`door-type-qty-view-${index}`"
                class="alternate-listing-row custom-border-top"
              >
                <td>
                  <v-text-field
                    dense
                    filled
                    placeholder="Enter Size"
                    :value="row.size"
                    readonly
                    solo
                    flat
                    color="cyan"
                  />
                </td>
                <td v-if="isJob || quoteType == 2">
                  <v-text-field
                    dense
                    filled
                    placeholder="Enter Serial Number"
                    :value="row.serial_no"
                    readonly
                    solo
                    flat
                    color="cyan"
                  />
                </td>
                <td>
                  <v-text-field
                    dense
                    filled
                    placeholder="Enter QTY"
                    solo
                    flat
                    :value="row.qty"
                    readonly
                    color="cyan"
                  />
                </td>
                <td v-if="!isJob">
                  <v-text-field
                    dense
                    filled
                    placeholder="Enter Price"
                    solo
                    flat
                    :value="row.price"
                    readonly
                    color="cyan"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin.js";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { cloneDeep, toSafeInteger } from "lodash";

export default {
  name: "line-item-view-v2",
  mixins: [CommonMixin],
  props: {
    quoteType: {
      type: Number,
      default: 1,
    },
    isJob: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drag: false,
      vWidth: {
        sno: 40,
        unit: 100,
        quantity: 100,
        discount: 100,
        rate: 100,
        total: 100,
        action: 80,
      },
      product_type: null,
      product_dialog: false,
      child_index: null,
      append_child: false,
      tax_active: false,
      show_price: true,
      show_image: false,
      is_second_child: false,
      discount_level: "transaction",
      subtotal: 0,
      discount: 0,
      gst: 0,
      gst_amount: 0,
      adjustment: 0,
      total: 0,
      discount_value: null,
      discount_type: 1,
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
      qty_object: {},
      qty_array: [],
      qty_dialog: false,
    };
  },
  methods: {
    close_qty_dialog() {
      this.qty_object = {};
      this.qty_array = [];
      this.qty_dialog = false;
    },
    view_qty_dialog(row) {
      this.qty_object = row;
      this.qty_array = cloneDeep(row.qty_array);
      this.qty_dialog = true;
    },
    get_col_style(param, child, second_child) {
      let width = 50;
      if (param == "product") {
        if (!this.show_price) {
          width = 70;
        } else if (this.show_price) {
          width = 40;
          if (this.discount_level == "transaction") {
            width = 50;
          }
        }
      }
      if (second_child) {
        return { width: `${width - 10}%` };
      }
      return { width: child ? `${width - 5}%` : `${width}%` };
    },
  },
  components: {
    Dialog,
  },
  computed: {
    dialog_width() {
      return toSafeInteger((document.body.clientWidth / 100) * 50);
    },
    ...mapGetters(["lViewLineItem", "lViewCalculation"]),
  },
};
</script>
<style scoped>
i.v-icon.notranslate.mdi.mdi-menu-down.theme--light {
  display: none;
}
</style>
