<template>
  <div class="btx-ck-editor">
    <ckeditor
      :editor="editor"
      @input="onInput"
      :value="editorData"
      :config="editorConfig"
    ></ckeditor>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editorReady: false,
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "outdent",
            "indent",
            "|",
            "bulletedList",
            "numberedList",
            "blockQuote",
            "|",
            "undo",
            "lineHeight",
            "LineHeight",
            "redo",
          ],

          shouldNotGroupWhenFull: true,
        },
        lineHeight: {
          options: ["1", "1.5", "2", "2.5", "3"], // Line height options
        },
      },
    };
  },
  watch: {
    value() {
      this.init();
    },
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
    init() {
      this.editorData = this.lodash.toString(this.value);
    },
  },
  mounted() {
    this.init();
  },
  components: {
    ckeditor: CKEditor.component,
  },
};
</script>
